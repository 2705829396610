import React, { useEffect } from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { confirmAlert } from 'react-confirm-alert'
import TreeItem from '@mui/lab/TreeItem';
import BookmarkKeeping from './subcomps/BookmarkKeeping';
import Support from './subcomps/Support'
import HistoryKeeping from './subcomps/HistoryKeeping';
import ContentFrame from './subcomps/ContentFrame';
import MyaccountTable from './subcomps/MyaccountTable';
import "./Hom.css";
import Searched from '../components/subcomps/Searched';
import Search from '../components/subcomps/SearchedV2'
import LoginIcon from '@mui/icons-material/Login';
// import './style.css';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import LogoutIcon from '@mui/icons-material/Logout';
import { Modal, Spinner } from 'react-bootstrap'
import IconButton from '@mui/material/IconButton';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import HistoryIcon from '@mui/icons-material/History';
import { useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Hexapi from '../usefulcomps/HexAPI/Hexapi';
import { Accordion, AccordionSummary, AccordionDetails, fabClasses } from '@mui/material';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
// import Navbar from './MenuItems.js/Navbar';
import { useRef } from "react";
import './MenuItems.css'
import IIADVCL from '../usefulcomps/table/IIADVCL';
import HomeIcon from '@mui/icons-material/Home';
import SGST from '../usefulcomps/table/Sgstcomparitive';
import Circular from '../usefulcomps/table/Circular';
import GstTable from '../usefulcomps/table/GstDataTable';
import Notificationtable from "../usefulcomps/table/Notificationtable";
import { LocalConvenienceStoreOutlined } from '@material-ui/icons';
import Hsnsearch from '../components/subcomps/Hsnsearch';
import Dictionary from './subcomps/Dictionary';
import Comparelaws from './subcomps/Comparelaws';
import { Aesencryption } from '../usefulcomps/Aes/Aes'
import CloseIcon from '@mui/icons-material/Close';
// import LogoutIcon from '../images/logout.png'
import SupportIcon from '../images/support.png'
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import OrderDatatable from '../usefulcomps/table/OrderDatatable';
import { Login } from '@mui/icons-material';

const Homescreen = () => {


    const [states, setstate] = useState({
        // openCustomFrame: false,
        targetFile: '',
        isError: false,
        // historyFileData: [],
        // isReferedByHistory: false,
        isOpenSearchModal: false,
        isReferedByHsn: false,
        hsnSearchWord: "",
        hsnPath: "",
        isOpenBookmarkModal: false,
        isOpenHistoryModal: false,
    })
    const [userDetails, setuserDetails] = useState([])
    const [isOpenSupport, setisOpenSupport] = useState(false)
    const [isReferedByHistory, setisReferedByHistory] = useState(false)
    const [isOpenCompareLaws, setisOpenCompareLaws] = useState(false)
    const [isOpenDictModal, setisOpenDictModal] = useState(false)
    const [isOpenHSNSearch, setisOpenHSNSearch] = useState(false)
    const [openCircNoti, setopenCircNoti] = useState(false);
    const [historyFileData, sethistoryFileData] = useState([]);
    const [openCustomFrame, setOpencustomFrame] = useState(false);
    const [subfolder, setsubfolder] = useState('');
    const [mainFolder, setmainFolder] = useState('');
    const [url, seturl] = useState(sessionStorage.getItem('url'));
    const [userid, setid] = useState(sessionStorage.getItem('userid'));
    const [searchapi, setapi] = useState(sessionStorage.getItem('searchapi'));
    const [loginstatus, setstatus] = useState(false);
    const [isMyAccountClicked, setisMyAccountClicked] = useState(false);
    const [username, setusername] = useState('username');
    const [remaininglogincount, setremaininglogincount] = useState(0);
    const [value, setvalue] = useState(0);
    const [message, setmessage] = useState('');
    const [itemclicked, setitemclicked] = useState(false);
    const [itemclicked2, setitemclicked2] = useState(false);
    const [itemclicked3, setitemclicked3] = useState(false);
    const [itemclicked4, setitemclicked4] = useState(false)
    const [itemclicked5, setitemclicked5] = useState(false)
    const [itemclicked6, setitemclicked6] = useState(false)
    const [isforward, setisforward] = useState(false);
    const [sgstfile, setsgstfile] = useState(false);
    const [banner, setbanner] = useState("Banner");
    const [tile1, settile1] = useState("Tile");
    const [tile2, settile2] = useState("Tile");
    const [tile3, settile3] = useState("Tile");
    const [tile4, settile4] = useState("Tile");
    const [tile5, settile5] = useState("Tile");
    const [tile6, settile6] = useState("Tile");
    const [showtiledata,setshowtiledata]=useState(false);
    const [circulartype, setcirculartype] = useState('')
    const [notificationtype, setnotificationtype] = useState('');
    const [gsttype, setgsttype] = useState('');
    const [folderpath, setfolderpath] = useState('')
    const [showHome, setshowHome] = useState(false);
    const [circularhead, setcircularhead] = useState("");
    const [notificationhead, setnotificationhead] = useState("");
    const [gsthead, setgsthead] = useState("");
    const [showdemo, setshowdemo] = useState(false)
    const [ismobile, setismobile] = useState(window.innerWidth < 1200)
    const [othercaselaw, setothercaselaw] = useState("");
    const [clname, setclname] = useState('');
    const [showGST, setshowGST] = useState(false);
    const [orderfile,setorderfile]=useState('');

    const lawData = [
        {
            name: "GST",
            children: [
                {
                    name: "CGST",
                    children: [
                        {
                            name: "Act",
                            link: "Assets/data5t/gt/gtcgst/index_01.htm",
                            parent: "CGST",
                        },
                        {
                            name: "Rules",
                            link: "Assets/data5t/gt/gtstrl/index.htm",
                            parent: "CGST",
                        },
                        {
                            name: "Notification-other than rate of tax",
                            link: "Assets/data5t/gt/ctntnr/central_tax_noti23.htm",
                            parent: "CGST",
                        },
                        {
                            name: "Notification- relating to rate of tax",
                            link: "Assets/data5t/gt/ctntra/central_tax_rate_noti_23.htm",
                            parent: "CGST",
                        },

                        {
                            name: "Circulars",
                            link: "Assets/data5t/gt/ctcirc/index23.htm",
                            parent: "CGST",
                        },
                        {
                            name: "Order",
                            link: "Assets/data5t/gt/ctordr/index_order22.htm",
                            parent: "CGST",
                        },
                        {
                            name: "Removal of Difficulty",
                            link: "Assets/data5t/gt/ctorod/index_removal_order20.htm",
                            parent: "CGST",
                        },
                        {
                            name: "Instructions",
                            link: "Assets/data5t/gt/gtcgcu/ins_index23.htm",
                            parent: "CGST",
                        },
                        {
                            name: "Guidelines",
                            link: "Assets/data5t/gt/gtcggu/guide_index.htm",
                            parent: "CGST",
                        },
                    ],
                    parent: "GST",

                },
                {
                    name: "IGST",
                    children: [
                        {
                            name: "Act",
                            link: "Assets/data5t/gt/gtigst/index_02.htm",
                            parent: "IGST",
                        },
                        {
                            name: "Rules",
                            link: "Assets/data5t/gt/gtistr/index.htm",
                            parent: "IGST",
                        },
                        // {
                        //     name: "Notification(RATE) Comparitive Chart",
                        //     link: "Assets/data5t/gt/gtnoti/gtnoti_comparision22.htm",
                        //     parent: "IGST",
                        // },
                        {
                            name: "Notification-Other than rate of tax",
                            link: "Assets/data5t/gt/igntnr/integrated_tax_noti21.htm",
                            parent: "IGST",
                        },
                        {
                            name: "Notification- Relating to rate of tax",
                            link: "Assets/data5t/gt/igntra/integrated_tax_rate_noti23.htm",
                            parent: "IGST",
                        },

                        {
                            name: "Circulars",
                            link: "Assets/data5t/gt/gtigcu/index2.htm",
                            parent: "IGST",
                        },
                    ]
                },
                {
                    name: "UTGST",
                    children: [
                        {
                            name: "Act",
                            link: "Assets/data5t/gt/gtugst/index_03.htm",
                            parent: "UTGST",
                        },
                        {
                            name: "Rules",
                            link: "Assets/data5t/gt/gtugru/index.htm",
                            parent: "UTGST",
                        },
                        // {
                        //     name: "Notification Ready Reckoner",
                        //     link: "Assets/data5t/gt/gttari/index.htm",
                        //     parent: "UTGST",
                        // },
                        {
                            name: "Notification-Other than rate of tax",
                            link: "Assets/data5t/gt/ugntnr/unionterritory_tax_noti23.htm",
                            parent: "UTGST",
                        },
                        {
                            name: "Notification- relating to rate of tax",
                            link: "Assets/data5t/gt/ugntra/union_tax_rate_noti23.htm",
                            parent: "UTGST",
                        },

                        {
                            name: "Order (Removal of Difficulty)",
                            link: "Assets/data5t/gt/ugordr/index_ugst_rod19.htm",
                            parent: "UTGST",
                        },
                        // {
                        //     name: "Notification Comparitive Chart",
                        //     link: "Assets/data5t/gt/gtnoti/gtnoti_comparision22.htm",
                        //     parent: "UTGST",
                        // },
                    ]
                },
                {
                    name: "SGST",
                    link: 'Assets/Data5t/gt/gtsupt/index.htm',
                    parent: "GST",
                },
                {
                    name: "GST(Comp. to the States) Act",
                    children: [
                        {
                            name: "Act",
                            link: "Assets/data5t/gt/gtcomp/com_index.htm",
                            parent: "GST",
                        },
                        {
                            name: "Rules",
                            link: "Assets/data5t/gt/gtcoru/comp_rule_index.htm",
                            parent: "GST",
                        },
                        {
                            name: "Notification- Other than rate of tax",
                            link: "Assets/data5t/gt/compnr/Compensation_Cess_noti23.htm",
                            parent: "GST",
                        },
                        {
                            name: "Notification- relating to rate of tax",
                            link: "Assets/data5t/gt/compra/compensation_cess_rate_noti23.htm",
                            parent: "GST",
                        },

                        {
                            name: "Circular",
                            link: "Assets/data5t/gt/gtcoci/index.htm",
                            parent: "GST",
                        },


                    ]
                },
                {
                    name: "Press Release",
                    link: 'Assets/Data5t/gt/gtpres/index.htm',
                    parent: "GST",
                },
                {
                    name: "Portal Related Updates",
                    link: 'Assets/Data5t/gt/gstpru/port_index23.htm',
                    parent: "GST",
                },
                {
                    name: "Council Meeting Updates",
                    link: 'Assets/Data5t/gt/gtport/index.htm',
                    parent: "GST",
                },
                // {
                //     name: "Notification Comparitive Chart",
                //     link: "Assets/data5t/gt/gtnoti/gtnoti_comparision23.htm",
                //     parent: "GST",
                // },
                // {
                //     name: "Notification Ready Reckoner",
                //     link: "Assets/data5t/gt/gtcgst/index2.htm",
                //     parent: "GST",
                // },
                // {
                //     name: "GST News",
                //     link: 'Assets/Data5t/gt/gtnews/news_index.htm',
                //     parent: "GST",
                // },
                {
                    name: "Case Laws",
                    link: 'Assets/Data5t/cu/cutari/index.htm',
                    parent: "GST",
                },
                {
                    name: "Advance Ruling",
                    link: 'Assets/Data5t/cu/cutari/index.htm',
                    parent: "GST",
                },
                {
                    name: "Forms",
                    link: 'Assets/Data5t/gt/gtcfrm/index_category.htm',
                    parent: "GST",
                },
                {
                    name: "CBIC FAQs",
                    link: 'Assets/Data5t/gt/gtfaqb/index.htm',
                    parent: "GST",
                },
                {
                    name: "Statutory Timelimits",
                    link: 'Assets/Data5t/GT/GTstat/index.htm',
                    parent: "GST",
                },
                {
                    name: "GST Rates",
                    link: 'Assets/Data5t/gt/gttari/gst_tari_index.htm',
                    parent: "GST",
                },
                {
                    name: "Reference Bank",
                    link: 'Assets/data5t/NS/gtbank/index.htm',
                    parent: "GST",
                },
            ]
        },
        {
            name: "CUSTOMS",
            children: [
                {
                    name: "Customs Act,1962",
                    link: 'Assets/data5t/cu/cuacts/index62.htm',
                    parent: "CUSTOMS",
                },
                {
                    name: "Customs Tariff Act, 1975",
                    link: 'Assets/data5t/cu/cuacts/index75.htm',
                    parent: "CUSTOMS",
                },
                {
                    name: "Rules",
                    link: 'Assets/Data5t/cu/curule/index.htm',
                    parent: "CUSTOMS",
                },
                {
                    name: "Circular/Instructions",
                    link: 'Assets/Data5t/cu/curule/index.htm',
                    parent: "CUSTOMS",
                },
                {
                    name: 'Notification-Non Tariff',
                    link: 'Assets/Data5t/cu/curule/index.htm',
                    type: 'excise nt',
                    parent: "CUSTOMS",
                },
                {
                    name: 'Notification-Tariff',
                    link: 'Assets/Data5t/cu/cutari/index.htm',
                    type: 'excise tariff',
                    parent: "CUSTOMS",
                },
                {
                    name: "Order",
                    link: 'Assets/Data5t/cu/cutari/index.htm',
                    parent: "CUSTOMS",
                },
                {
                    name: "Regulation",
                    link: 'Assets/Data5t/cu/curegu/index.htm',
                    parent: "CUSTOMS",
                },
                {
                    name: "Manual",
                    link: 'Assets/Data5t/cu/cumanu/index.htm',
                    parent: "CUSTOMS",
                },
                {
                    name: "Tariff",
                    link: 'Assets/Data5t/cu/cutari/index.htm',
                    parent: "CUSTOMS",
                },
                {
                    name: "Case Laws",
                    link: 'Assets/Data5t/cu/cutari/index.htm',
                    parent: "CUSTOMS",
                },
                {
                    name: "Advance Ruling",
                    link: 'Assets/Data5t/cu/cutari/index.htm',
                    parent: "CUSTOMS",
                },
                {
                    name: "Forms",
                    link: 'Assets/Data5t/cu/cuform/index.htm',
                    parent: "CUSTOMS",
                },
                {
                    name: "CBIC FAQs",
                    link: 'Assets/Data5t/cu/cufaqb/index.htm',
                    parent: "CUSTOMS",
                },
                {
                    name: "Custom Rates",
                    link: 'Assets/Data5t/cu/curate/index.htm',
                    parent: "CUSTOMS",
                },
            ]
        },

        {
            name: "FTP ",
            children: [
                {
                    name: "Act",
                    link: 'Assets/Data5t/ft/ftacts/index.htm',
                    parent: "FTP",
                },
                {
                    name: "Rules",
                    link: 'Assets/Data5t/ft/ftrule/index.htm',
                    parent: "FTP",
                },
                {
                    name: "Policy",
                    link: 'Assets/Data5t/ft/ftpoli/index.htm',
                    parent: "FTP",
                },
                {
                    name: "Handbook of Procedure",
                    link: 'Assets/Data5t/ft/ftproc/index.htm',
                    parent: "FTP",
                },
                {
                    name: "Circular",
                    link: 'Assets/Data5t/ft/ftcirc/index.htm',
                    parent: "FTP",
                },
                {
                    name: "Notification",
                    link: 'Assets/Data5t/ft/ftnoti/index.htm',
                    parent: "FTP",
                },
                {
                    name: "Trade Notice",
                    link: 'Assets/Data5t/ft/fttrad/index.htm',
                    parent: "FTP",
                },
                {
                    name: "Public Notice",
                    link: 'Assets/Data5t/ft/ftpubl/index.htm',
                    parent: "FTP",
                },
                {
                    name: "ITC (HS)",
                    link: 'Assets/Data5t/ft/ftitch/index.htm',
                    parent: "FTP",
                },
                {
                    name: "SION",
                    link: 'Assets/Data5t/ft/ftsion/index.htm',
                    parent: "FTP",
                },
                {
                    name: "Appendix",
                    link: 'Assets/Data5t/ft/ftappe/index.htm',
                    parent: "FTP",
                },
                {
                    name: "Aayat Niryat Form (ANF)",
                    link: 'Assets/Data5t/ft/ftappe/ft23_listanfs.htm',
                    parent: "FTP",
                },
                {
                    name: "Case Laws",
                    link: 'Assets/Data5t/ft/ftappe/index.htm',
                    parent: "FTP",
                },
                // {
                //     name: "Committee Meeting Updates",
                //     link: 'Assets/Data5t/ft/ftpcmu/index.htm',
                //     parent: "FTP",
                // },
                {
                    name: "DGFT FAQs",
                    link: 'Assets/Data5t/ft/ftfaqb/index.htm',
                    parent: "FTP",
                },
                {
                    name: "Reference Bank",
                    link: 'Assets/Data5t/NS/ftbank/index.htm',
                    parent: "FTP",
                },
                {
                    name: "PRC Meeting",
                    link: 'Assets/Data5t/ft/ftprco/index.htm',
                    parent: "FTP",
                },
            ]
        },
        {
            name: "SEZ ",
            children: [
                {
                    name: "Act",
                    link: 'Assets/data5t/sz/szacts/index_main.htm',
                    parent: "SEZ",
                },
                {
                    name: "Rules",
                    link: 'Assets/data5t/sz/szrule/index_main.htm',
                    parent: "SEZ",
                },
                {
                    name: "Policy",
                    link: 'Assets/data5t/sz/szpoli/index_main.htm',
                    parent: "SEZ",
                },
                {
                    name: "Circular",
                    link: 'Assets/data5t/sz/szcirc/index_main.htm',
                    parent: "SEZ",
                },
                {
                    name: "Notification",
                    link: 'Assets/data5t/sz/sznoti/index_main.htm',
                    parent: "SEZ",
                },
                {
                    name: "Appendix Form",
                    link: 'Assets/data5t/sz/szappe/index_main.htm',
                    parent: "SEZ",
                },
                {
                    name: "FEMA on SEZ",
                    link: 'Assets/data5t/sz/szfema/index_main.htm',
                    parent: "SEZ",
                },
                {
                    name: "Minutes",
                    link: 'Assets/data5t/sz/szrule/Index_boa_min_meet.htm',
                    parent: "SEZ",
                },

            ]
        },
        {
            name: "EXCISE",
            children: [
                {
                    name: "Act",
                    link: 'Assets/Data5t/ce/ceacts/index.htm',
                    parent: "EXCISE",
                },
                {
                    name: "Rules",
                    link: 'Assets/Data5t/ce/cerule/index.htm',
                    parent: "EXCISE",
                },
                {
                    name: "Circular/Instructions/Guidelines",
                    link: 'Assets/Data5t/cu/curule/index.htm',
                    parent: "EXCISE",
                },
                {
                    name: "Notification-Non Tariff",
                    link: 'Assets/Data5t/cu/curule/index.htm',
                    parent: "EXCISE",
                },
                {
                    name: 'Notification-Tariff',
                    link: 'Assets/Data5t/cu/curule/index.htm',
                    type: 'excise tariff',
                    parent: "EXCISE",
                },
                {
                    name: "Manual",
                    link: 'Assets/Data5t/ce/cemanu/index.htm',
                    parent: "EXCISE",
                },
                {
                    name: "Tariff",
                    link: 'Assets/Data5t/ce/cetari/index.htm',
                    parent: "EXCISE",
                },
                {
                    name: "Case Laws",
                    link: 'Assets/Data5t/ce/cetari/index.htm',
                    parent: "EXCISE",
                },
                {
                    name: "Forms",
                    link: 'Assets/Data5t/ce/ceform/index.htm',
                    parent: "EXCISE",
                }
            ]
        },
        {
            name: "SERVICE TAX",
            children: [
                {
                    name: "Act",
                    link: 'Assets/Data5t/st/stacts/index.htm',
                    parent: "SERVICE TAX",
                },
                {
                    name: "Rules",
                    link: 'Assets/Data5t/st/strule/index.htm',
                    parent: "SERVICE TAX",
                },
                {
                    name: "Circular/Instructions/ Guidelines",
                    link: 'Assets/Data5t/cu/curule/index.htm',
                    parent: "SERVICE TAX",
                },
                {
                    name: "Notification",
                    link: 'Assets/Data5t/cu/curule/index.htm',
                    type: 'service tax',
                    parent: "SERVICE TAX",
                },
                {
                    name: "Finance Act",
                    link: 'Assets/Data5t/st/stfina/index.htm',
                    parent: "SERVICE TAX",
                },
                {
                    name: "Service Tax Schemes",
                    link: 'Assets/Data5t/st/stsche/index.htm',
                    parent: "SERVICE TAX",
                },
                {
                    name: "List of Negative Services",
                    link: 'Assets/Data5t/st/stnews/index.htm',
                    parent: "SERVICE TAX",
                },
                {
                    name: "Ready Reckoner",
                    link: 'Assets/Data5t/st/stmisc/index.htm',
                    parent: "SERVICE TAX",
                },
                {
                    name: "Case Laws",
                    link: 'Assets/Data5t/st/stmisc/index.htm',
                    parent: "SERVICE TAX",
                },
                {
                    name: "Forms",
                    link: 'Assets/Data5t/st/stform/index.htm',
                    parent: "SERVICE TAX",
                },

            ]
        },
        {
            name: "FEMA",
            children: [
                {
                    name: "Act",
                    link: 'Assets/Data5t/rb/rbacts/index.htm',
                    parent: "FEMA",
                },
                {
                    name: "Rules",
                    link: 'Assets/Data5t/rb/rbrule/index.htm',
                    parent: "FEMA",
                },
                {
                    name: "Circular",
                    link: 'Assets/Data5t/rb/rbcirc/index.htm',
                    parent: "FEMA",
                },
                {
                    name: "Notification",
                    link: 'Assets/Data5t/rb/rbnoti/index.htm',
                    parent: "FEMA",
                },
                {
                    name: "Regulation",
                    link: 'Assets/Data5t/rb/rbregu/index.htm',
                    parent: "FEMA",
                },
                {
                    name: "Citizen Charter",
                    link: 'Assets/Data5t/rb/rbciti/index.htm',
                    parent: "FEMA",
                },
                {
                    name: "Forms",
                    link: 'Assets/Data5t/rb/rbform/index.htm',
                    parent: "FEMA",
                },
                {
                    name: "RBI FAQs",
                    link: 'Assets/Data5t/rb/rbfaqb/index.htm',
                    parent: "FEMA",
                },

            ]
        },
        {
            name: "CASE LAWS",
            link: 'Assets/Data5t/gt/gtnews/adv_rul_index.htm',
            parent: "Case Laws",
        },
        {
            name: "ADVANCE RULING",
            link: 'Assets/Data5t/gt/gtnews/adv_rul_index.htm',
            parent: "Advance Ruling",
        },
        {
            name: "SMART TARIFF",
            link: 'Assets/Data5t/cu/cutari/index.htm',
            parent: "Smart Tariff",
        },
        {
            name: "MISC",
            children: [
                {
                    name: "Community Insights",
                    link: 'Assets/data5t/ar/mscart/index.htm',
                    parent: "MISC",
                },
                {
                    name: "HSN FINDER",
                    link: 'Assets',
                    parent: "MISC",
                },
                {
                    name: "SGST Comparitive Chart",
                    link: 'Assets/Data5t/bu/bubill/index_main.htm',
                    parent: "MISC",
                },
                {
                    name: "Compare Law",
                    link: 'Assets/Data5t',
                    parent: "MISC",
                },
                {
                    name: "Budget",
                    link: 'Assets/Data5t/bu/bubill/index_main.htm',
                    parent: "MISC",
                },
                {
                    name: "Flowchart",
                    link: 'Assets/Data5t/fl/flgstn/index.htm',
                    parent: "MISC",
                },
                {
                    name: "Update Log",
                    link: 'Assets/data5t/gt/gtnews/updates_current_2023.htm',
                    parent: "MISC",
                },
                {
                    name: "Question Bank",
                    link: 'Assets/Data5t/gt/gtillu/index.htm',
                    parent: "MISC",
                },
                {
                    name: "Allied Acts",
                    link: 'Assets/Data5t/cu/cualar/index.htm',
                    parent: "MISC",
                },
                {
                    name: "Legal Dictionary",
                    link: 'Assets',
                    parent: "MISC",
                },
                {
                    name: "Specimen",
                    link: 'Assets/Data5t/sp/spappe/litigation1.htm',
                    parent: "MISC",
                },
                {
                    name: "Practice and Procedure",
                    link: 'Assets/Data5t/gt/gtwork/index.htm',
                    parent: "MISC",
                },
            ]
        },
    ]

    const data = [
        {
            id: 1,
            img: 'https://images.unsplash.com/photo-1547005327-ef75a6961556?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8b2NlYW58ZW58MHwyfDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60',
        },
        {
            id: 2,
            img: 'https://images.unsplash.com/photo-1480926965639-9b5f63a0817b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fG9jZWFufGVufDB8MnwwfHw%3D&auto=format&fit=crop&w=800&q=60',
        },
        {
            id: 3,
            img: 'https://images.unsplash.com/photo-1566024287286-457247b70310?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fG9jZWFufGVufDB8MnwwfHw%3D&auto=format&fit=crop&w=800&q=60',
        },
        {
            id: 4,
            img: 'https://images.unsplash.com/photo-1494791368093-85217fbbf8de?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8b2NlYW58ZW58MHwyfDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60',
        },
        {
            id: 5,
            img: 'https://images.unsplash.com/photo-1551405780-03882d5a2ba7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTN8fG9jZWFufGVufDB8MnwwfHw%3D&auto=format&fit=crop&w=800&q=60',
        },
    ];

    const getFiltersValue=(field, renderfile, clfile,firstfilter)=>{
        // console.warn("Running")
        var filterStr = '';
        let obj = {
            'query': `[dbo].[Lawcrux_Web_Proc_InteractiveIndexAdvCl_GetFilterData]@Field='{0}'`,
            'queryArr': [`${field}`]
        }
        Hexapi(obj).then(res => {
            // console.warn(res);
            let defaultValue= [];
            let Filters = res["filters"];
            var obj = { values: {}, def: {}, oldvalues: {} }
            for (var i = 1; i < Filters.length; i++) {
                console.log(Filters[i].defval)
                obj.def[Filters[i].filtername] = Filters[i].defval
                obj.values[Filters[i].filtername] = Filters[i].values
                obj.oldvalues[Filters[i].filtername] = Filters[i].defval
            }
            // console.log(obj)
            defaultValue.push(obj);
            // setFilterdata(re)
            if (field == 'adv') {

                // Filter for Advanced Ruling
                
                let filterStrFirst = '';
                let courts = [];
                let states = [];
                if (Object.keys(defaultValue[0].def).length != 0) {
                    var keys = Object.keys(defaultValue[0].def)
                    for (var i = 0; i < keys.length; i++) {
                        if (i < keys.length - 1) {
                            // FilterStr contains the filtered string for year and TBD after the loop
                            if (keys[i] == "Year") {
                                const value = defaultValue[0].def["Year"].split(",");
                                let allyear = "";
                                for (let i = 0; i <= value.length; i++) {
                                    if (value[i] != undefined) {
                                        let yeardata = value[i].split("-");
                                        const to = yeardata[0];
                                        const from = yeardata[1];
                                        for (let i = to; i <= from; i++) {
                                            allyear += i + ",";
                                        }
                                    }
                                }
                                // console.log(allyear.split(0,allyear.length-1) )
                                // console.log(allyear.slice(0, allyear.length - 1));
                                filterStr += allyear.slice(0, allyear.length - 1) + '$#12$$$';
                            } else if (keys[i] == "Authority") {
                                // console.log(defaultValue[0].def[keys[i]].split(','));
                                courts = defaultValue[0].def[keys[i]].split(',').sort();
                                // filterStr += this.state.defaultValue[0].def[keys[i]] + '$#12$$$'    
                            } else if (keys[i] == "State") {
                                states = defaultValue[0].def[keys[i]].split(',').sort();
                            } else if (keys[i] == "Subject") {
                                filterStrFirst += defaultValue[0].def[keys[i]] + ','
                            }
                            // filterStr += this.state.defaultValue[0].def[keys[i]] + '$#12$$$'
                        } else {
                            filterStr += defaultValue[0].def[keys[i]]
                        }
                    }
                }
    
                if (courts.indexOf('') != -1) {
                    courts.shift();
                }
    
                if (states.indexOf('') != -1) {
                    states.shift();
                }
                let filterStrCourts = '';
                for (let i = 0; i < courts.length; i++) {
                    for (let j = 0; j < states.length; j++) {
                        if ((i == (courts.length - 1)) && (j == (states.length - 1))) {
                            filterStrCourts += courts[i] + '-' + states[j] + '$#12$$$';
                        } else {
                            filterStrCourts += courts[i] + '-' + states[j] + ',';
                        }
                    }
                }
                filterStr = filterStrFirst + "$#12$$$" + filterStrCourts + filterStr;
                // FilterStr is the final filtered string with courts-states, year and TBD
                console.log("FilterString:" + filterStr);
                // this.state.allcheckedvalues = filterStr;
            } else {
                // Filter for Case Laws
                // let filterStr = '';
                let filterStrFirst = '';
                let filterStrLast = '';
                let courts = [];
                let states = [];
                // console.warn(Object.keys(defaultValue[0].def));
                if (Object.keys(defaultValue[0].def).length != 0) {
                    var keys = Object.keys(defaultValue[0].def)
                    // console.log(keys);
                    for (var i = 0; i < keys.length; i++) {
                        if (i < keys.length - 1) {
                            // FilterStr contains the filtered string for year and TBD after the loop
                            if (keys[i] == "Year") {
                                const value = defaultValue[0].def["Year"].split(",");
                                let allyear = "";
                                for (let i = 0; i <= value.length; i++) {
                                    if (value[i] != undefined) {
                                        let yeardata = value[i].split("-");
                                        const to = yeardata[0];
                                        const from = yeardata[1];
                                        for (let i = to; i <= from; i++) {
                                            allyear += i + ",";
                                        }
                                    }
                                }
                                // console.log(allyear.split(0,allyear.length-1) )
                                // console.log(allyear.slice(0, allyear.length - 1));
                                filterStrLast += allyear.slice(0, allyear.length - 1) + '$#12$$$';
                            } else if (keys[i] == "Courts") {
                                // console.log(defaultValue[0].def[keys[i]].split(','));
                                courts = defaultValue[0].def[keys[i]].split(',').sort();
                                // filterStr += this.state.defaultValue[0].def[keys[i]] + '$#12$$$'    
                            } else if (keys[i] == "State") {
                                states = defaultValue[0].def[keys[i]].split(',').sort();
                            } else if (keys[i] == "Subject" || keys[i] == "Favour") {
                                filterStrFirst += defaultValue[0].def[keys[i]] + '$#12$$$';
                            }
                            // filterStr += this.state.defaultValue[0].def[keys[i]] + '$#12$$$'
                        } else {
                            filterStrLast += defaultValue[0].def[keys[i]];
                        }
                    }
                }
    
                if (courts.indexOf('') != -1) {
                    courts.shift();
                }
    
                if (states.indexOf('') != -1) {
                    states.shift();
                }
                
                let filterStrCourts = '';
                // FilterStrCourts contains the filtered string for courts and states
                for (let i = 0; i < courts.length; i++) {
                    // If the courts are Supreme Court or NAPA, just add it to the filterStrFirst string for ease of use
                    if (courts[i] == "Supreme Court of India" || courts[i] == "National Antiprofiteering Authority") {
                        filterStrFirst += courts[i] + ',';
                        continue;
                    }
                    for (let j = 0; j < states.length; j++) {
                        filterStrCourts += states[j] + ' ' + courts[i] + ',';
                    }
                }
                filterStr = filterStrFirst + filterStrCourts + '$#12$$$' + filterStrLast;

                // FilterStr is the final filtered string with courts-states, year and TBD
                console.log("FilterString:" + filterStr);
                // this.state.allcheckedvalues = filterStr;
            }
        })
        .then(()=>{
            const filter=firstfilter+filterStr;
            // console.warn(filter)
            setothercaselaw(filter);
            setsgstfile(false);
            setfilerender(renderfile);
            setfolderpath("cl");
            setclname(clfile);
        })
    }



    const setInitialPage = (child_obj, parent) => {
        if (child_obj.link != "" && child_obj.link != undefined) {
            // setstate({ setstate.targetFile: child_obj.link, setstate.openCustomFrame: true, openCircNoti: false })
            // setstate({ openCustomFrame: true });
            setOpencustomFrame(true);
            setstate({ targetFile: child_obj.link });
            setshowdemo(false)
            let child = child_obj.name.toLowerCase()
            parent = parent.toLowerCase()
            console.log(child, parent);

            if (parent == 'gst') {
                if (child == 'act') {
                    setsubfolder('gtcomp'); setmainFolder('gt');
                    setfilerender('content')
                }
                else if (child == 'sgst') {
                    setsubfolder('gtsupt'); setmainFolder('gt');
                    setfilerender('content')
                }
                else if (child == 'rules') {
                    setsubfolder('gtcomp'); setmainFolder('gt');
                    setfilerender('content')
                } else if (child == 'notification- other than rate of tax') {
                    // setsubfolder('gtnoti'); setmainFolder('gt');
                    // setfilerender('content')
                    setgsttype("GST(Comp)-Non-Rate")
                    setgsthead("GST(Comp) Notification - Non-Rate")
                    setfolderpath("gt");

                } else if (child == 'notification- relating to rate of tax') {
                    // setsubfolder('gtnoti'); setmainFolder('gt');
                    // setfilerender('content')
                    setgsttype("GST(Comp)-Rate")
                    setgsthead("GST(Comp) Notification - Rate")
                    setfolderpath("gt");

                } else if (child == 'circular') {
                    // setsubfolder('gtcoci'); setmainFolder('gt');
                    // setfilerender('content')
                    setgsttype("GST(Comp) Circulars")
                    setgsthead("GST(Comp) Circulars")
                    setfolderpath("gt");

                } else if (child == 'press release') {
                    // setsubfolder('gtpres'); setmainFolder('gt');
                    // setfilerender('content')
                    setgsttype("GST Press Release")
                    setgsthead("GST Press Release")
                    setfolderpath("gt");
                }
                else if (child == 'notification comparitive chart') {
                    setsubfolder('gtnoti'); setmainFolder('gt');
                    setfilerender('content')

                }
                else if (child == 'notification ready reckoner') {
                    setsubfolder('gtcgst'); setmainFolder('gt');
                    setfilerender('content')

                }
                else if (child == 'notification(rate) ready reckoner') {
                    setsubfolder('gttari'); setmainFolder('gt');
                    setfilerender('content')

                }
                else if (child == 'portal related updates') {
                    // setsubfolder('gtcgcu'); setmainFolder('gt');
                    // setfilerender('content')
                    setgsttype("portal related updates")
                    setgsthead("GST Portal Related Updates")
                    setfolderpath("gt");
                }
                else if (child == 'council meeting updates') {
                    // setsubfolder('gtport'); setmainFolder('gt');
                    // setfilerender('content')
                    setgsttype("council meeting updates")
                    setgsthead("GST Council Meeting Updates")
                    setfolderpath("gt");

                } else if (child == 'gst news') {
                    setsubfolder('gtnews'); setmainFolder('gt');
                    setfilerender('content')
                }
                else if (child == 'forms') {
                    setsubfolder('gtcfrm'); setmainFolder('gt');
                    setfilerender('content')

                }
                else if (child == 'advance ruling') {
                    getFiltersValue("adv",'iiadv',"GST-Advance Ruling",",GST$#12$$$")
                }
                else if (child == 'case laws') {
                    getFiltersValue("cl",'iicl',"GST-Caselaw",",GST$#12$$$" )

                }
                else if (child == 'cbic faqs') {
                    // setstate({ subfolder: 'cumanu', mainFolder: 'cu' })
                    setsubfolder('gtfaqb'); setmainFolder('gt');
                    setfilerender('content')

                }
                else if (child == 'statutory timelimits') {
                    // setstate({ subfolder: 'cumanu', mainFolder: 'cu' })
                    setsubfolder('gtstat'); setmainFolder('gt');
                    setfilerender('content')

                }
                else if (child == 'gst rates') {
                    // setstate({ subfolder: 'cumanu', mainFolder: 'cu' })
                    setsubfolder('gttari'); setmainFolder('gt');
                    setfilerender('content')

                }else if (child == 'gst rates') {
                    // setstate({ subfolder: 'cumanu', mainFolder: 'cu' })
                    setsubfolder('gttari'); setmainFolder('gt');
                    setfilerender('content')

                }else if (child == 'reference bank') {
                    // setstate({ subfolder: 'cumanu', mainFolder: 'cu' })
                    setsubfolder('gtbank'); setmainFolder('NS');
                    setfilerender('content')

                }
            }

            else if (parent == 'cgst') {
                if (child == 'act') {
                    setsubfolder('gtcgst'); setmainFolder('gt');
                    setfilerender('content')
                }
                else if (child == 'rules') {
                    setsubfolder('gtstrl'); setmainFolder('gt');
                    setfilerender('content')
                }
                else if (child == 'notification-other than rate of tax') {
                    // setsubfolder('gtnoti'); setmainFolder('gt');
                    // setfilerender('content')
                    setgsttype("CGST-Non-Rate")
                    setgsthead("CGST Notification - Non-Rate")
                    setfolderpath("gt");
                }
                else if (child == 'notification- relating to rate of tax') {
                    // setsubfolder('gtnoti'); setmainFolder('gt');
                    // setfilerender('content')
                    setgsttype("CGST-Rate")
                    setgsthead("CGST Notification - Rate")
                    setfolderpath("gt");
                }
                else if (child == 'circulars') {
                    // setsubfolder('gtcgcu'); setmainFolder('gt');
                    // setfilerender('content')
                    setgsttype("CGST-Circular")
                    setgsthead("CGST Circular")
                    setfolderpath("gt");
                }
                else if (child == 'order') {
                    // setsubfolder('gtcgcu'); setmainFolder('gt');
                    // setfilerender('content')
                    setgsttype("CGST-Order")
                    setgsthead("CGST Order")
                    setfolderpath("gt");
                }
                else if (child == 'removal of difficulty') {
                    // setsubfolder('gtcgcu'); setmainFolder('gt');
                    // setfilerender('content')
                    setgsttype("CGST-ROD")
                    setgsthead("CGST ROD")
                    setfolderpath("gt");
                }
                else if (child == 'instructions') {
                    // setsubfolder('gtcgcu'); setmainFolder('gt');
                    // setfilerender('content')
                    setgsttype("CGST-Instructions")
                    setgsthead("CGST Instructions")
                    setfolderpath("gt");
                }
                else if (child == 'guidelines') {
                    // setsubfolder('gtcgcu'); setmainFolder('gt');
                    // setfilerender('content')
                    setgsttype("CGST-Guidelines")
                    setgsthead("CGST Guidelines")
                    setfolderpath("gt");
                }
            }

            else if (parent == 'utgst') {
                if (child == 'act') {
                    setsubfolder('gtugst'); setmainFolder('gt');
                    setfilerender('content')
                }
                else if (child == 'rules') {
                    setsubfolder('gtugst'); setmainFolder('gt');
                    setfilerender('content')
                }
                else if (child == 'notification ready reckoner') {
                    setsubfolder('gttari'); setmainFolder('gt');
                    setfilerender('content')
                }
                else if (child == 'notification-other than rate of tax') {
                    // setsubfolder('gtnoti'); setmainFolder('gt');
                    // setfilerender('content')
                    setgsttype("UTGST Notification Non-Rate")
                    setgsthead("UTGST Notification - Non-Rate")
                    setfolderpath("gt");
                }
                else if (child == 'notification- relating to rate of tax') {
                    // setsubfolder('gtnoti'); setmainFolder('gt');
                    // setfilerender('content')
                    setgsttype("UTGST Notification-Rate")
                    setgsthead("UTGST Notification - Rate")
                    setfolderpath("gt");
                }
                else if (child == 'order (removal of difficulty)') {
                    // setsubfolder('gtugst'); setmainFolder('gt');
                    // setfilerender('content')
                    setgsttype("UTGST-OROD")
                    setgsthead("UTGST Order (Removal of Difficulty)")
                    setfolderpath("gt");
                }
                else if (child == 'notification comparitive chart') {
                    setsubfolder('gtnoti'); setmainFolder('gt');
                    setfilerender('content')
                }
            }

            else if (parent == 'igst') {
                if (child == 'act') {
                    setsubfolder('gtigst'); setmainFolder('gt');
                    setfilerender('content')
                }
                else if (child == 'rules') {
                    setsubfolder('gtistr'); setmainFolder('gt');
                    setfilerender('content')
                }
                else if (child == 'notification(rate) comparitive chart') {
                    setsubfolder('gtnoti'); setmainFolder('gt');
                    setfilerender('content')
                }
                else if (child == 'notification-other than rate of tax') {
                    // setsubfolder('gtnoti'); setmainFolder('gt');
                    // setfilerender('content')
                    setgsttype("IGST Notification-Non-Rate")
                    setgsthead("IGST Notification - Non-Rate")
                    setfolderpath("gt");
                }
                else if (child == 'notification- relating to rate of tax') {
                    // setsubfolder('gtnoti'); setmainFolder('gt');
                    // setfilerender('content')
                    setgsttype("IGST Notification - Rate")
                    setgsthead("IGST Notification - Rate")
                    setfolderpath("gt");
                }
                else if (child == 'circulars') {
                    // setsubfolder('gtigcu'); setmainFolder('gt');
                    // setfilerender('content')
                    setgsttype("IGST Circulars")
                    setgsthead("IGST Circulars")
                    setfolderpath("gt");
                }
            }



            else if (parent == 'customs') {
                if (child == 'customs act,1962') {
                    setsubfolder('cuacts'); setmainFolder('cu');
                    setfilerender('content')

                } else if (child == 'customs tariff act, 1975') {
                    setsubfolder('cuacts'); setmainFolder('cu');
                    setfilerender('content')

                } else if (child == 'rules') {
                    setsubfolder('curule'); setmainFolder('cu');
                    setfilerender('content')

                }
                else if (child == "circular/instructions") {
                    console.log("customs")
                    setcirculartype('customs')
                    setcircularhead('Circular - Custom')
                }
                else if (child == 'notification-non tariff') {
                    setnotificationtype("custom NT")
                    setnotificationhead("Notification - Custom Non-Tariff")
                    setfolderpath("cu");
                }
                else if (child == 'notification-tariff') {
                    setnotificationtype("custom tariff")
                    setnotificationhead("Notification - Custom Tariff ")
                    setfolderpath("cu");

                }
                else if (child == 'order') {
                    setorderfile("cu")
                    setcircularhead('Custom Order')
                }
                else if (child == 'tariff') {
                    setsubfolder('cutari'); setmainFolder('cu');
                    setfilerender('content')

                }
                else if (child == 'regulation') {
                    setsubfolder('curegu'); setmainFolder('cu');
                    setfilerender('content')

                }
                else if (child == 'manual') {
                    setsubfolder('cumanu'); setmainFolder('cu');
                    setfilerender('content')

                }
                else if (child == 'procedure & articles') {
                    setsubfolder('gtwork'); setmainFolder('gt');
                    setfilerender('content')

                }
                else if (child == 'case laws') {
                    getFiltersValue("cl",'iicl',"Custom-Caselaw",",Custom$#12$$$")
                }
                else if (child == 'advance ruling') {
                    getFiltersValue("adv",'iiadv',"Custom-Advance Ruling",",Custom$#12$$$")
                }
                else if (child == 'forms') {
                    setsubfolder('cuform'); setmainFolder('cu');
                    setfilerender('content')

                }
                else if (child == 'cbic faqs') {
                    setsubfolder('cufaqb'); setmainFolder('cu');
                    setfilerender('content')

                }else if (child == 'custom rates') {
                    setsubfolder('curate'); setmainFolder('cu');
                    setfilerender('content')

                }
            }
            else if (parent == 'ftp') {
                if (child == 'act') {
                    setsubfolder('ftacts'); setmainFolder('ft');
                    setfilerender('content')
                } else if (child == 'rules') {
                    setsubfolder('ftrule'); setmainFolder('ft');
                    setfilerender('content')
                }
                else if (child == 'handbook of procedure') {
                    setsubfolder('ftproc'); setmainFolder('ft');
                    setfilerender('content')
                }
                else if (child == 'circular') {
                    setsubfolder('ftcirc'); setmainFolder('ft');
                    setfilerender('content')

                } else if (child == 'notification') {
                    setsubfolder('ftnoti'); setmainFolder('ft');
                    setfilerender('content')

                } else if (child == 'appendix') {
                    setsubfolder('ftappe'); setmainFolder('ft');
                    setfilerender('content')

                } else if (child == 'itc (hs)') {
                    setsubfolder('ftitch'); setmainFolder('ft');
                    setfilerender('content')

                } else if (child == 'policy') {
                    setsubfolder('ftpoli'); setmainFolder('ft');
                    setfilerender('content')

                } else if (child == 'trade notice') {
                    // this.setState({ subfolder: 'fttrad', mainFolder: 'ft' })
                    setsubfolder('fttrad'); setmainFolder('ft');
                    setfilerender('content')

                } else if (child == 'public notice') {
                    // this.setState({ subfolder: 'ftpubl', mainFolder: 'ft' })
                    setsubfolder('ftpubl'); setmainFolder('ft');
                    setfilerender('content')

                } else if (child == 'sion') {
                    // this.setState({ subfolder: 'ftsion', mainFolder: 'ft' })
                    setsubfolder('ftsion'); setmainFolder('ft');
                    setfilerender('content')

                }
                else if (child == 'aayat niryat form (anf)') {
                    setsubfolder('ftappe'); setmainFolder('ft');
                    setfilerender('content')
                }
                else if (child == 'case laws') {
                    getFiltersValue("cl", 'iicl',"Foreign Trade-Caselaw",",Foreign Trade$#12$$$" )
                }
                else if (child == 'committee meeting updates') {
                    setsubfolder(''); setmainFolder('');
                    setfilerender('content')
                }
                else if (child == 'dgft faqs') {
                    setsubfolder('ftqb'); setmainFolder('ft');
                    setfilerender('content')
                }
                else if (child == 'reference bank') {
                    // setstate({ subfolder: 'cumanu', mainFolder: 'cu' })
                    setsubfolder('ftbank'); setmainFolder('NS');
                    setfilerender('content')

                }
                else if (child == 'prc meeting') {
                    // setstate({ subfolder: 'cumanu', mainFolder: 'cu' })
                    setsubfolder('ftprco'); setmainFolder('ft');
                    setfilerender('content')

                }
            }

            else if (parent == 'case laws') {
                if (child == 'case laws')
                    setfilerender('iicl')
                setsgstfile(false)
                setfolderpath("cl");

            }
            else if (parent == 'sez') {
                if (child == 'act') {
                    setsubfolder('szacts'); setmainFolder('sz');
                    setfilerender('content')
                }
                else if (child == 'rules') {
                    setsubfolder('szrule'); setmainFolder('sz');
                    setfilerender('content')
                }
                else if (child == 'policy') {
                    setsubfolder('szpoli'); setmainFolder('sz');
                    setfilerender('content')
                }
                else if (child == 'circular') {
                    setsubfolder('szacts'); setmainFolder('sz');
                    setfilerender('content')
                }
                else if (child == 'notification') {
                    setsubfolder('sznoti'); setmainFolder('sz');
                    setfilerender('content')
                }
                else if (child == 'appendix form') {
                    setsubfolder('szappe'); setmainFolder('sz');
                    setfilerender('content')
                }
                else if (child == 'fema or sez') {
                    setsubfolder('szfema'); setmainFolder('sz');
                    setfilerender('content')
                }
                else if (child == 'minutes') {
                    setsubfolder('szrule'); setmainFolder('sz');
                    setfilerender('content')
                }
            }
            if (parent == 'excise') {
                if (child == 'act') {
                    setsubfolder('ceacts'); setmainFolder('ce');
                    setfilerender('content')

                } else if (child == 'rules') {
                    setsubfolder('cerule'); setmainFolder('ce');
                    setfilerender('content')

                } else if (child == 'circular/instructions/guidelines') {
                    setcirculartype('excise')
                    setcircularhead('Circular-Excise')
                    setfolderpath("ce");


                } else if (child == 'notification-non tariff') {
                    setfolderpath("ce");
                    setnotificationtype("excise NT")
                    setnotificationhead("Notification - Excise Non-Tariff")


                }
                else if (child == 'notification-tariff') {
                    setfolderpath("ce");
                    setnotificationtype("excise tariff")
                    setnotificationhead("Notification - Excise Tariff ")


                }
                else if (child == 'manual') {
                    setsubfolder('cemanu'); setmainFolder('ce');
                    setfilerender('content')

                } else if (child == 'tariff') {
                    setsubfolder('cetari'); setmainFolder('ce');
                    setfilerender('content')

                }
                else if (child == 'case laws') {
                    getFiltersValue("cl",'iicl',"Central Excise-Caselaw",",Central Excise$#12$$$");
                }
                else if (child == 'forms') {
                    setsubfolder('ceform'); setmainFolder('ce');
                    setfilerender('content')

                }
            }
            else if (parent == 'service tax') {
                if (child == 'act') {
                    setsubfolder('stacts'); setmainFolder('st');
                    setfilerender('content')
                }
                else if (child == 'rules') {
                    setsubfolder('strule'); setmainFolder('st');
                    setfilerender('content')
                }
                else if (child == 'circular/instructions/ guidelines') {
                    setcirculartype('service tax')
                    setcircularhead('Circular-Service Tax')
                    setfolderpath("st");
                }
                else if (child == 'notification') {
                    setnotificationtype("service tax")
                    setnotificationhead("Notification - Service Tax")
                    setfolderpath("st");

                }
                else if (child == 'finance act') {
                    setsubfolder('stfina'); setmainFolder('st');
                    setfilerender('content')
                }
                else if (child == 'service tax schemes') {
                    setsubfolder('stsche'); setmainFolder('st');
                    setfilerender('content')
                }
                else if (child == 'list of negative services') {
                    setsubfolder('stnews'); setmainFolder('st');
                    setfilerender('content')
                }
                else if (child == 'ready reckoner') {
                    setsubfolder('stmisc'); setmainFolder('st');
                    setfilerender('content')
                }
                else if (child == 'case laws') {
                    getFiltersValue("cl",'iicl',"Service Tax-Caselaw",",Service Tax$#12$$$")
                }
                else if (child == 'forms') {
                    setsubfolder('stmisc'); setmainFolder('st');
                    setfilerender('content')
                }

            }

            else if (parent == "fema") {
                if (child == 'act') {
                    setsubfolder('rbacts'); setmainFolder('rb');
                    setfilerender('content')
                }
                else if (child == 'rules') {
                    setsubfolder('rbrule'); setmainFolder('rb');
                    setfilerender('content')
                }
                else if (child == 'circular') {
                    setsubfolder('rbcirc'); setmainFolder('rb');
                    setfilerender('content')
                }
                else if (child == 'notification') {
                    setsubfolder('rbnoti'); setmainFolder('rb');
                    setfilerender('content')
                }
                else if (child == 'regulation') {
                    setsubfolder('rbregu'); setmainFolder('rb');
                    setfilerender('content')
                }
                else if (child == 'citizen charter') {
                    setsubfolder('rbciti'); setmainFolder('rb');
                    setfilerender('content')
                }
                else if (child == 'forms') {
                    setsubfolder('rbform'); setmainFolder('rb');
                    setfilerender('content')
                }
                else if (child == 'rbi faqs') {
                    setsubfolder('rbfaq'); setmainFolder('rb');
                    setfilerender('content')
                }
            }
            else if (parent == 'advance ruling') {
                if (child == 'advance ruling') {
                    // setsubfolder('gtnews'); setmainFolder('gt');
                    setclname("")
                    setfilerender('iiadv')
                    setsgstfile(false)
                    setfolderpath("cl");
                }
            }
            else if (parent == 'case laws') {
                if (child == 'case laws') {
                    // setsubfolder('gtnews'); setmainFolder('gt');
                    setclname("")
                    setfilerender('iicl')
                    setsgstfile(false)
                    setfolderpath("cl");
                }
            }
            else if (parent == 'smart tariff') {
                if (child == 'smart tariff') {
                    setsubfolder('cutari'); setmainFolder('cu');
                    setfilerender('content')
                }
            }
            else if (parent == 'misc') {
                if (child == 'hsn finder') {
                    // setsubfolder(''); setmainFolder('');
                    // setfilerender('content')
                    setisOpenHSNSearch(true);
                    setfilerender('')

                }
                else if (child == "community insights") {
                    setsubfolder('mscart'); setmainFolder('ar');
                    setfilerender('content')
                }
                else if (child == 'sgst comparitive chart') {
                    console.log('setsgst');
                    setfilerender('');
                    setsgstfile(true);
                }
                else if (child == 'compare law') {
                    setisOpenCompareLaws(true);
                    setfilerender('')

                }
                else if (child == "update log") {
                    setsubfolder('gtnews'); setmainFolder('gt');
                    setfilerender('content')
                }
                else if (child == 'budget') {
                    setsubfolder('bubill'); setmainFolder('bu');
                    setfilerender('content')
                }
                else if (child == 'flowchart') {
                    setsubfolder(''); setmainFolder('');
                    setfilerender('content')
                }
                else if (child == 'question bank') {
                    setsubfolder('gtillu'); setmainFolder('gt');
                    setfilerender('content')
                }
                else if (child == 'allied acts') {
                    setsubfolder('cualar'); setmainFolder('cu');
                    setfilerender('content')
                }
                else if (child == 'legal dictionary') {
                    setisOpenDictModal(true)
                    setfilerender('')

                }
                else if (child == 'question bank') {
                    setsubfolder('gtillu'); setmainFolder('gt');
                    setfilerender('content')
                }
                else if (child == 'specimen') {
                    setsubfolder('spappe'); setmainFolder('sp');
                    setfilerender('content')
                }
                else if (child == 'practice and procedure') {
                    setsubfolder('gtwork'); setmainFolder('gt');
                    setfilerender('content')
                }


            }
        } else if (child_obj.type != undefined) {
            // setstate({ targetFile: Object.assign(child_obj, { type: child_obj.type.toLowerCase() }), openCustomFrame: false, openCircNoti: true })
            setstate({ targetFile: Object.assign(child_obj, { type: child_obj.type.toLowerCase() }) });
            // setstate({ openCustomFrame: false });
            setOpencustomFrame(false);
            setopenCircNoti(true);
        }
        // setTimeout(() => {
        //     console.log(subfolder, mainFolder)
        // }, 2000);

    }



    const [filerender, setfilerender] = useState('');

    const MenuItems = ({ items, depthLevel, setInitialPage }) => {
        const [dropdown, setDropdown] = useState(false);

        const ref = useRef();

        const handlearrows = (items) => {
            console.log(items);
            if (loginstatus) {
                if ((itemclicked && itemclicked2 && itemclicked3) || showdemo == false) {
                    setDropdown(!dropdown);
                    setInitialPage(items, items.parent);
                }
            } else {
                window.top.location.href = `${sessionStorage.getItem('Apipathurl')}login`;
            }
        }



        const handlechild = (items) => {
            if (loginstatus) {

                if ((itemclicked && itemclicked2 && itemclicked3) || showdemo == false) {
                    setInitialPage(items, items.parent);
                }
            } else {
                window.top.location.href = `${sessionStorage.getItem('Apipathurl')}login`;
            }
        }

        const onMouseEnter = () => {
            if (itemclicked3 == true || showdemo == false) {
                setDropdown(true);
            }
        };

        const onMouseLeave = () => {
            if (itemclicked == true || showdemo == false) {
                setDropdown(false);
            }
        };
        depthLevel = depthLevel + 1;
        const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";
        return (
            <div
                className="menu-items"
                style={ismobile ? {} : depthLevel < 2 && items.children ? { maxWidth: '8%', minWidth: '7%' } : depthLevel < 2 ? { minWidth: '10%', maxWidth: '12%' } : {}}
                // ref={ref}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                {items.children || depthLevel < 2 ? (
                    <>
                        <div style={depthLevel < 2 ? ismobile ? { backgroundColor: 'white', boxShadow: ' rgba(0,0,0,.04) 0 10px 15px' } : { backgroundColor: 'white', boxShadow: ' rgba(0,0,0,.04) 0 10px 15px', display: 'flex', justifyContent: 'center' } : { backgroundColor: "white", paddingTop: '5%', paddingLeft: '5%' }}
                            className={itemclicked3 || loginstatus == false ? "menu-buttons" : ""}>
                            <span
                                style={loginstatus == false ? {} : itemclicked3 ? {} : showdemo == false ? {} : { backgroundColor: 'rgb(249, 249, 249)' }}
                                onClick={() => { handlearrows(items) }}

                            >

                                {depthLevel < 2 ? <b style={{ padding: '2px' }}>{items.name}</b> : items.name}

                                {/* {(items.children==null || items.children==undefined) ?<b>{items.name}</b>:<></>} */}
                                {depthLevel < 2 ? <span></span> : items.children.length > 0 && <span className="arrow"/>}
                            </span>
                        </div>
                        {dropdown && items.children != null && (
                            <ul
                                className={`dropdown-menu ${dropdownClass} ${dropdown ? "show" : ""}`}
                                style={items.name == 'MISC'&& !ismobile ?{left:'-75%'}:{}}
                            >
                                {items.children.map((submenu, index) => (
                                    <li
                                        key={index}
                                        onClick={
                                            () => handlearrows(submenu)
                                        }
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <MenuItems
                                            items={submenu}
                                            key={index}
                                            depthLevel={depthLevel}
                                            setInitialPage={setInitialPage}
                                        />
                                    </li>

                                ))}

                            </ul>
                        )}
                    </>
                ) : (
                    <div
                        className={itemclicked3 || loginstatus == false ? "menu-subitems" : ""}
                        style={depthLevel < 2 ? {} : { paddingTop: '5%', paddingLeft: '5%', cursor: "pointer" }}
                        key={items.name}
                        onClick={
                            () => handlechild(items)
                        }
                    >
                        {items.name}
                    </div>
                )}
            </div>
        );
    };

    const [isLoadingModalOpen, setisLoadingModalOpen] = useState(false);

    const confirmBox = () => {
        if (loginstatus == true) {
            confirmAlert({
                message: 'Are you sure you want to logout?',
                buttons: [
                    {
                        label: 'No',
                        onClick: () => {
                            // window.location.hash = "#Home"
                            // this.addActiveClass("Home", Home, window.location.hash, 0)
                            // this.setContentBox("Home")
                        }
                    },
                    {
                        label: 'Yes',
                        onClick: () => {
                            // this.setState({ isLoadingModalOpen: true })
                            setisLoadingModalOpen(true);
                            let obj = {
                                query: `[dbo].[Lawcrux_Web_Proc_User_Logout] @UserID = '{0}'`,
                                queryArr: [`${userid}`]
                            }
                            Hexapi(obj).then(resp => {
                                console.log(resp)
                                //   this.setState({ isLoadingModalOpen: false })
                                setisLoadingModalOpen(false);
                                localStorage.clear()
                            }).then(() => window.top.location.href = `${sessionStorage.getItem('Apipathurl')}login`)// 
                                .catch(err => console.error(err))
                        }
                    }
                ]
            })
        } else {
            window.top.location.href = `${sessionStorage.getItem('Apipathurl')}login`;
        }

    }



    const checkstatus = () => {
        console.log(loginstatus);
        if (loginstatus == false) {
            window.top.location.href = `${sessionStorage.getItem('Apipathurl')}login`;
            setisReferedByHistory(false)
            setTimeout(() => {
                setstate({ isOpenSearchModal: false, isOpenBookmarkModal: false, isOpenHistoryModal: false });
            }, 100);
            setOpencustomFrame(false);
            setisMyAccountClicked(false);
            // setusername('signup');

            
        }
    }

    // const filepath='Assets/Data5t/gt/gtnews/adv_rul_index.htm';


    useEffect(() => {
        if (sessionStorage.getItem('userid') != undefined) {
        var obj = {
            'query': `[dbo].[Lawcrux_Web_Proc_EditUser_GetFormData] @RecID='{0}'`,// @RecID='{0}'
            'queryArr': [`${sessionStorage.getItem('userid')}`]
          }
          Hexapi(obj).then(resp => {
            console.log(resp)
            if (resp[''].length > 0) {
              setuserDetails(resp[''][0])
            }
          })
          var obj1 = {
            'query': `[dbo].[Lawcrux_Web_Proc_User_Login_CheckV2] @UserID='{0}',@Details='{1}'`,// @RecID='{0}'
                'queryArr': [`${sessionStorage.getItem('userid')}`,`${localStorage.getItem('auth_lawcrux_id')}`]    
          }
          Hexapi(obj1).then(resp => {
            
            console.log(resp)
            if (resp[''].length > 0) {
              if(resp[''][0]['command'] == 0){
                var msg = resp[''][0]['msg']
                window.top.location.href = `${sessionStorage.getItem('Apipathurl')}login?`+btoa(`msg=${msg}`)
              }
            }
          })
          const check = setInterval(() => {
            var obj1 = {
                'query': `[dbo].[Lawcrux_Web_Proc_User_Login_CheckV2] @UserID='{0}',@Details='{1}'`,// @RecID='{0}'
                'queryArr': [`${sessionStorage.getItem('userid')}`,`${localStorage.getItem('auth_lawcrux_id')}`]
              }
              Hexapi(obj1).then(resp => {
                console.log(resp)
                if (resp[''].length > 0) {
                    if(resp[''][0]['command'] == 0){
                        var msg = resp[''][0]['msg']
                        window.top.location.href = `${sessionStorage.getItem('Apipathurl')}login?`+btoa(`msg=${msg}`)
                    }
                  }
              })
          }, 120000);//3600000
          check
        }
        var obj1 = {
            'query': `[dbo].[Lawcrux_Web_Proc_Homescreen_Panel_GetData] `,// @RecID='{0}'
            'queryArr': []
        }
        Hexapi(obj1).then(resp => {
            console.log(resp[''].length)
            if (resp['banner'] != undefined) {
                setbanner(resp['banner'])
            }else{
                setbanner('Banner')
            }
            if (resp['tile1'] != undefined) {
                settile1(resp['tile1'])
            }else{
                settile1('Tile')
            }
            if (resp['tile2'] != undefined) {
                settile2(resp['tile2'])
            }else{
                settile2('Tile')
            }
            if (resp['tile3'] != undefined) {
                settile3(resp['tile3'])
            }else{
                settile3('Tile')
            }
            if (resp['tile4'] != undefined) {
                settile4(resp['tile4'])
            }else{
                settile4('Tile')
            }
            if (resp['tile5'] != undefined) {
                settile5(resp['tile5'])
            }else{
                settile5('Tile')
            }
            if (resp['tile6'] != undefined) {
                settile6(resp['tile6'])
            }else{
                settile6('Tile')
            }
        })
        console.log(userid);
        if (sessionStorage.getItem('userid') != undefined) {
            var obj2 = {
                'query': `[dbo].[Lawcrux_Web_Proc_Homescreen_GetData] @UserID='{0}' `,// @RecID='{0}'
                'queryArr': [`${sessionStorage.getItem('userid')}`]
            }
            Hexapi(obj2).then(resp => {
                console.log(resp)
                if (resp[''].length > 0) {
                    if(resp['alert'] != undefined){
                        setvalue(resp['alert'][0].alert);
                        setmessage(resp['alert'][0].msg);
                    }
                    setremaininglogincount(resp[''][0].remaininglogins);
                    console.log(resp[''][0].remaininglogins)
                    // sessionStorage.setItem("username",resp[''][0].username);
                    setusername(resp[''][0].username);
                    sessionStorage.setItem("username", resp[''][0].username)
                }
                if (resp["illustration"][2].illustration1 == 0) {
                    setshowdemo(false)
                } else {
                    setshowdemo(true)
                }
            })
            setstatus(true);

        } else {
            setusername('Sign In');
            setstatus(false);
            setshowdemo(false)

        }

        const handleResize = () => {
            setismobile(window.innerWidth < 1200);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

        
    }, [])



    const panelstyle = (loginstatus) ? (itemclicked && itemclicked2 && itemclicked3) ?
        {} :
        { overflow: 'hidden' } : {}



    const account = () => {
        if (loginstatus == false) {
            window.top.location.href = `${sessionStorage.getItem('Apipathurl')}login`;
        }else{
            if (userid == 1) {
                setisMyAccountClicked(true);
            } else {
                let queryParameters = Aesencryption(`sessionuserid=${userid}&userid=${userid}&apipathurl=${sessionStorage.getItem('Apipathurl')}`)
                window.top.location.href = `${sessionStorage.getItem('url')}memberform?${queryParameters}`
            }
        }
        
    }

    const innerpanelstyle = (loginstatus) ? (itemclicked && itemclicked2 && itemclicked3) ?
    { marginLeft: '3%', width: ' 99%', overflow: 'hidden', marginBottom: '50px' } : { marginLeft: '3%', width: ' 99%', overflow: 'hidden', marginBottom: '50px' }
    : ismobile ? { marginLeft: '3%', width: ' 99%', overflow: 'hidden', marginTop: '230px', marginBottom: '50px' } : { marginLeft: '3%', width: ' 99%', overflow: 'hidden', marginBottom: '50px' }

const loginscreen = (loginstatus) ? 
                        (value == 0) ?
                            (itemclicked && itemclicked2 && itemclicked3) ? 
                                { overflow: 'hidden', paddingTop: '140px' } 
                            : { overflow: 'hidden', paddingTop: '120px' } 
                        : { overflow: 'hidden', paddingTop: '140px' } 
                    : { overflow: 'hidden', paddingTop: '140px' }

const loginstyle = (loginstatus) ? { marginLeft: '4%', fontFamily: 'anton', fontSize: 'small' } : { borderRadius: '5px', marginLeft: '-130%', width: ' 180%', backgroundColor: 'black', boxShadow: ' rgba(0, 0, 0, 0.35) 0px 5px 15px', color: 'white' }

const menuitems = (itemclicked3) ? { zIndex: '0' } : { zIndex: '0' };

const renderhomepage = () => {
    setisforward(false);
    setfilerender('')
}
const MarkDoneIllustration1 = () => {
    let obj2 = {
        'query': `[dbo].[Lawcrux_Web_Proc_Illustration_Update] @UserID='{0}', @Illustration='{1}' `,// @RecID='{0}'
        'queryArr': [`${sessionStorage.getItem('userid')}`, '1']
    }
    Hexapi(obj2).then(resp => {
        console.log(resp)
    });
}


    const handleforward = (value) => {
        console.log(value)
        setisforward(value)
        setfilerender("");
        setsgstfile(false);
        setcirculartype("");
        setcircularhead('')
        setnotificationtype("")
        setgsttype("")
        setnotificationhead('')
        setothercaselaw('')
        setorderfile('');
    }


    return (
        <>
            {
                
            }
             {
                
                itemclicked3 == false && loginstatus == true && showdemo ?
                    <>
                        <div class="react-portal" style={{ overflow: 'hidden', width: '100%' }}>
                            <div class="popover " id="popovermodal" style={
                                itemclicked3 ? {} : itemclicked2 ? { width: '428px', zIndex: '121', left: '75%', top: '8%' } : itemclicked ? { width: '428px', zIndex: '121', left: '75%', top: '18%' } : { width: '428px', zIndex: '121', left: '75%', top: '13%' }
                                // itemclicked ? { width: '428px', zIndex: '121', left: '10%', top: '14%' } : itemclicked ? { width: '428px', zIndex: '121', left: '75%', top: '17%' } :itemclicked3 ? { width: '428px', zIndex: '121', left: '75%', top: '17%' }  : { width: '428px', zIndex: '121', left: '75%', top: '13%' }
                            }>
                                {/* <span class="popover__arrow-item" style={{ left: '50%' }}></span> */}
                                <div style={{ width: '96%', margin: 'auto' }}>
                                    <div class="popover__header " style={{ display: 'flex', justifyContent: "space-evenly", alignItems: 'center' }}>
                                        <div>
                                            <span style={{ fontFamily: 'Roboto Condensed', fontSize: '22px', display: 'flex', justifyContent: 'center' }} >
                                                {
                                                    itemclicked ?
                                                        null
                                                        :
                                                        "Bookmark :"
                                                }
                                                {
                                                    itemclicked2 == false && itemclicked == true ?
                                                        "History :"
                                                        :
                                                        null
                                                }
                                                {
                                                    itemclicked2 == true && itemclicked3 == false ?
                                                        "Search :"
                                                        :
                                                        null
                                                }
                                                {/* {itemclicked ?"Search Icon "  : itemclicked2 ? "Bookmark Icon" : itemclicked3 ? "GST Menu"  :"History Icon"  } */}
                                            </span>
                                        </div>
                                        <span>
                                            {
                                                itemclicked ?
                                                    null
                                                    :
                                                    <BookmarksIcon />
                                            }
                                            {
                                                itemclicked2 == false && itemclicked == true ?
                                                    <HistoryIcon />
                                                    :
                                                    null
                                            }
                                            {
                                                itemclicked2 == true && itemclicked3 == false ?
                                                    <SearchIcon />
                                                    :
                                                    null
                                            }
                                            {/* {itemclicked ? <HistoryIcon /> : itemclicked2 ? <SearchIcon /> : itemclicked3 ? <BookmarksIcon /> : null} */}
                                        </span>
                                    </div>
                                    <div class="popover__body=" style={{ marginTop: '0' }}>
                                        <span >
                                            {
                                                itemclicked ?
                                                    null
                                                    :
                                                    "Access all of your bookmarked pages"
                                            }
                                            {
                                                itemclicked2 == false && itemclicked == true ?
                                                    "Lists your previously viewed files"
                                                    :
                                                    null
                                            }
                                            {
                                                itemclicked2 == true && itemclicked3 == false ?
                                                    "Quick access to the search engine"
                                                    :
                                                    null
                                            }
                                        </span>
                                    </div>
                                    <div class="popover_footer" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>

                                        <span style={{}} >
                                            {
                                                itemclicked == true ?
                                                    <FiberManualRecordOutlinedIcon style={{ height: '8px', width: '15px' }} />
                                                    :
                                                    <FiberManualRecordIcon style={{ height: '15px', width: '15px', color: 'rgb(241, 108, 108)' }} />
                                            }
                                            {
                                                itemclicked2 == false && itemclicked == true ?
                                                    <FiberManualRecordIcon style={{ height: '15px', width: '15px', color: 'rgb(241, 108, 108)' }} />
                                                    :
                                                    <FiberManualRecordOutlinedIcon style={{ height: '8px', width: '15px' }} />
                                            }
                                            {
                                                itemclicked2 == true && itemclicked3 == false ?
                                                    <FiberManualRecordIcon style={{ height: '15px', width: '15px', color: 'rgb(241, 108, 108)' }} />
                                                    :
                                                    <FiberManualRecordOutlinedIcon style={{ height: '8px', width: '15px' }} />
                                            }
                                        </span>
                                        {
                                            itemclicked ?
                                                null
                                                :
                                                <button style={{ width: '20%', marginBottom: '5px', borderRadius: '5px', backgroundColor: '#f16c6c', color: 'white', border: 'none' }} onClick={() => setitemclicked(true)}>Next</button>
                                        }
                                        {
                                            itemclicked2 == false && itemclicked == true ?
                                                <button style={{ width: '20%', marginBottom: '5px', borderRadius: '5px', backgroundColor: '#f16c6c', color: 'white', border: 'none' }} onClick={() => setitemclicked2(true)}>Next</button>
                                                :
                                                null
                                        }
                                        {
                                            itemclicked2 == true && itemclicked3 == false ?
                                                <button style={{ width: '20%', marginBottom: '5px', borderRadius: '5px', backgroundColor: '#f16c6c', color: 'white', border: 'none' }} onClick={() =>{ setitemclicked3(true); setshowdemo(false); MarkDoneIllustration1()}}>Got it</button>
                                                :
                                                null
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </>
                    :
                    <></>
            }
            {/* 
            <div style={(loginstatus && showdemo == true) ? (itemclicked && itemclicked2 && itemclicked3) ? { width: '100%' }
                : { bottom: 0, left: 0, position: 'absolute', right: 0, top: 0, opacity: '0.5', backgroundColor: 'rgb(75,72,72)', zIndex: '15' } : { width: '100%' }}>

            </div> */}


            {
                isforward == true ?
                    (
                        <div>
                            <div style={{ height: "95.8%", width: '100%',position:'fixed'}}>
                                {notificationtype == "" && circulartype == "" && orderfile == "" && gsttype == ""? sgstfile == false ? filerender == "iiadv" ?
                                    <IIADVCL field={"adv"} homedirect = {true} showheadernavbar={true} folderpath={folderpath} onstatechange={renderhomepage} callback={handleforward} showHome={true} deffilter={othercaselaw} clname={clname} />
                                    :
                                    <IIADVCL field={"cl"}  homedirect = {true} showheadernavbar={true}  folderpath={folderpath} onstatechange={renderhomepage} callback={handleforward} showHome={true} deffilter={othercaselaw} clname={clname} showillustration={true} />
                                    :
                                    <SGST callback={handleforward} showHome={true} /> : <></>}
                                {
                                    notificationtype == "" && circulartype != "" ?
                                        <Circular homedirect = {true} field={circulartype} showheadernavbar={true} circularheader={circularhead} showHome={true} folderpath={folderpath} callback={handleforward} />
                                        :
                                        <></>
                                }
                                {
                                    notificationtype != "" ?
                                        <Notificationtable homedirect = {true} showheadernavbar={true}  field={notificationtype} notificationheader={notificationhead} showHome={true} folderpath={folderpath} callback={handleforward} />
                                        : <></>
                                }
                                {
                                     notificationtype == "" && circulartype == "" && orderfile != "" ?
                                    <OrderDatatable homedirect = {true} field={orderfile} showheadernavbar={true} circularheader={circularhead} showHome={false} folderpath={folderpath} callback={handleforward} />
                                    : <></>
                                }
                                {
                                     gsttype !="" ?
                                     <GstTable homedirect = {true} showheadernavbar={true}  field={gsttype} gstheader={gsthead} showHome={true} folderpath={folderpath} callback={handleforward} />
                                     : <></>
                                }
                            </div>
                        </div>
                    )
                    :
                    isMyAccountClicked && (itemclicked && itemclicked2 && itemclicked3 || showdemo == false) ?
                        <MyaccountTable
                            goBack={() => setisMyAccountClicked(false)}
                            userid={userid}
                        />
                        :
                        <>
                            <div style={(loginstatus && showdemo == true) ?  itemclicked3 ? {
                                // position: 'absolute',
                                // top: 0,
                                // left: 0,
                                // right: 0,
                                // bottom: 0,
                                // backgroundColor: 'red',
                                // opacity: 0.5,
                                // zIndex: -1,
                            } : {
                                position: 'fixed', top: 0, left: 0, right: 0, bottom: 0,
                                backgroundColor: '#333333', opacity: 0.5, zIndex: 3
                            } : {}
                            }>
                            </div>

                            {
                                (loginstatus) ?
                                    <div style={value == 1 ? { zIndex: 15, position: 'fixed', width: '100%' } : {}}>
                                        {value == 1 ?
                                            <div style={{
                                                height: '20px',
                                                // background:'linear-gradient(to bottom, #00ff99 0%, #ccffff 100%)'
                                                background: '#eb5858'
                                            }}>
                                                <marquee class="marq" direction="left" loop="" >
                                                    <div class="geek1"><b style={{ color: 'white', fontFamily: "Amaranth,sans-serif !important" }}>{message}</b></div>
                                                </marquee>
                                            </div>
                                            :
                                            ""
                                        }
                                    </div>
                                    : null
                            }
                            <div className="topDiv" style={(loginstatus) ? value == 0 ? {} : { marginTop: '20px' } : {}}>
                                
                                <div style={(loginstatus && showdemo == true) ? itemclicked && itemclicked2 && itemclicked3 ? {
                                    // position: 'absolute',
                                    // top: 0,
                                    // left: 0,
                                    // right: 0,
                                    // bottom: 0,
                                    // backgroundColor: 'red',
                                    // opacity: 0.5,
                                    // zIndex: -1,
                                } : {
                                    position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
                                    backgroundColor: '#333333', opacity: 0.5, zIndex: 3
                                } : {}
                                }>
                                </div>
                                <div style={{ width: '95%', zIndex: 2 }}>
                                    
                                    {/*<div style={(loginstatus && showdemo==true) ? (itemclicked && itemclicked2 && itemclicked3) ? { width: '100%'/ *, height: '140px'* / }
                                        : { width: '100%' } : { width: '100%'/*, height: '140px'*n/ }}>*/}


                                    <div style={{
                                        display: 'flex', backgroundColor: 'rgb(255,255,255)',
                                        alignItems: 'center', height: '120px', justifyContent: 'space-between'
                                    }}>
                                        <div style={{ width: '60%', display: 'flex' }} >
                                            <div class="top_bar_right">
                                                <ul>
                                                    <li><i class="fas fa-mobile-alt"></i><a href="tel:+919212336582">+91-92123-36582</a></li>
                                                    <li><i class="fas fa-envelope"></i><a href="mailto:support@lawcrux.com">support@lawcrux.com</a></li>
                                                    <li><i class="fas fa-address-book"></i><a href="https://lawcrux.com/contact.html" target="_blank">Contact Us</a></li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        {/* <p style={{marginLeft: '10px',fontWeight: '600'}}>Remaining Logins:- {remaininglogincount}</p> */}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div style={{ width: '60%', display: 'flex' ,justifyContent:'flex-end'}} >
                                                <img src='lawcrux.png' height='130px' width='200px'></img>
                                            </div>
                                            {/* <h1>LawCrux</h1> */}
                                        </div>
                                        <div style={{ display: 'flex', paddingRight: '1%', justifyContent: 'space-between', }}>
                                        
                                            {/* <Tooltip title="Logout" >
                                            <div className='logoutBtn' onClick={() => { confirmBox() }}>
                                                <img src={LogoutIcon} alt="" />
                                            </div>
                                            </Tooltip > */}
                                            {/* <Tooltip title="Help" >
                                            <div className='supportBtn' onClick={() => this.setState({ isOpenSupport: true })}>
                                                <img src={SupportIcon} alt="" />
                                            </div>
                                            </Tooltip > */}
                                            <div>
                                                <div className='row'>
                                                    <div className='col-sm-8'>
                                                        <div style={loginstatus ? { marginTop: '0%' } : { marginTop: '80%' }}>
                                                            <button className="navbtn" style={loginstyle} onClick={() => { checkstatus(); account(); }}><b>{sessionStorage.getItem("userid") != undefined ? username.toUpperCase() : 'LOGIN'}</b>{sessionStorage.getItem("userid") != undefined ?null:<Login/>}</button>
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-4'>
                                                        {
                                                            loginstatus == true ?
                                                                <Tooltip title="Logout" >
                                                                <div style={{cursor:'pointer'}} onClick={() => { confirmBox() }}>
                                                                    <LogoutIcon />
                                                                </div>
                                                                </Tooltip >
                                                                : ""
                                                        }
                                                    </div>                                                      
                                                </div> 
                                            </div>
                                        </div>
                                        {/*</div>*/}
                                    </div>
                                    {/*</div>*/}
                                </div>
                                

                                <div style={{ width: '5%', backgroundColor: 'white', zIndex: 5, maxWidth: '10%' }}>
                                <div class="vl">
                                <div style={loginstatus ? { /*marginLeft: '10%'*/ } : { marginTop: '0%'/*, marginLeft: '10%'*/ }}>
                                        {/* <div className="col-1 bookmarkstyle" > */}
                                        <div style={(loginstatus && showdemo == true) ? itemclicked && itemclicked2 && itemclicked3 ? {
                                            // position: 'absolute',
                                            // top: 0,
                                            // left: 0,
                                            // right: 0,
                                            // bottom: 0,
                                            // backgroundColor: 'red',
                                            // opacity: 0.5,
                                            // zIndex: -1,
                                        } : {
                                            position: 'absolute', top: 0, left: '95%', right: 0, bottom: 0,
                                            backgroundColor: '#333333', opacity: 0.5, zIndex: 3
                                        } : {}
                                        }>
                                        </div>

                                        <div className="col-1" style=
                                            {itemclicked && itemclicked2 && !itemclicked3 ?
                                                { zIndex: 4, position: 'relative', width: '79%', borderRadius: '100%' }
                                                :
                                                { zIndex: 0, position: 'relative', width: '79%', borderRadius: '100%' }
                                            }>
                                            <Tooltip title="Search Icon" style={{ backgroundColor: 'white' }}>
                                                <IconButton style={itemclicked3 == false && itemclicked2 == true ? { color: 'black', background: 'white' } : { color: 'black' }}
                                                    onClick={(e) => {
                                                        
                                                        if (loginstatus == false) {
                                                            window.top.location.href = `${sessionStorage.getItem('Apipathurl')}login`;
                                                        }else{
                                                            if ((itemclicked && itemclicked2 && itemclicked3) || showdemo == false) {
                                                                setstate({ isOpenSearchModal: true });
                                                            }
                                                            setisReferedByHistory(false)
                                                            checkstatus();
                                                        }
                                                    }}
                                                >
                                                    <SearchIcon style={itemclicked3 == false && itemclicked2 == true ? { scale: '1.2' } : {}} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div className="col-1" style=
                                            {!itemclicked && !itemclicked2 && !itemclicked3 ?
                                                { zIndex: 4, position: 'relative', width: '79%', borderRadius: '100%' }
                                                :
                                                { zIndex: 0, position: 'relative', width: '79%', borderRadius: '100%' }
                                            }>
                                            <Tooltip title="Bookmark List">
                                                <IconButton style={loginstatus == true && showdemo == true ? itemclicked ? { color: 'black' } : { color: 'black', background: 'white' } : { color: 'black' }}
                                                    // onClick={() => setstate({ isOpenBookmarkModal: true })}
                                                    onClick={() => {
                                                        if (loginstatus == false) {
                                                            window.top.location.href = `${sessionStorage.getItem('Apipathurl')}login`;
                                                        }else{
                                                            if ((itemclicked && itemclicked2 && itemclicked3 ) || showdemo == false) {
                                                                setstate({ isOpenBookmarkModal: true });
                                                            }
                                                            checkstatus();
                                                        }
                                                    }}
                                                >
                                                    <BookmarksIcon style={loginstatus == true && showdemo == true ? itemclicked ? {} : { scale: '1.2' } : {}} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div className="col-1" style=
                                            {itemclicked && !itemclicked2 && !itemclicked3 ?
                                                { zIndex: 4, position: 'relative', width: '79%', borderRadius: '100%' }
                                                :
                                                { zIndex: 0, position: 'relative', width: '79%', borderRadius: '100%' }
                                            }>
                                            <Tooltip title="History">
                                                <IconButton style={itemclicked2 == false && itemclicked ? { color: 'black', background: 'white' } : { color: 'black' }}
                                                    // onClick={() => setstate({ isOpenHistoryModal: true })}
                                                    onClick={() => {
                                                        if (loginstatus == false) {
                                                            window.top.location.href = `${sessionStorage.getItem('Apipathurl')}login`;
                                                        }else{
                                                            if (itemclicked && itemclicked2 && itemclicked3 || showdemo == false) { setstate({ isOpenHistoryModal: true }); checkstatus() };
                                                        }
                                                    }}
                                                >
                                                    <HistoryIcon style={itemclicked2 == false && itemclicked ? { scale: '1.2' } : {}} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                                    
                                </div>
                            </div>
                            <div style={loginscreen}>
                                <div
                                    className="cls"
                                    style={menuitems}
                                >

                                    {/* <Navbar /> */}
                                    <div style={loginstatus == false ? { width: '100%' } : itemclicked3 ? { width: '100%' } : showdemo == false ? { width: '100%' } : { width: '100%' }}>
                                        <ul className="menus" style={ismobile ? {}:{paddingLeft:'1.5%'}}>
                                            {lawData.map((menu, index) => {
                                                const depthLevel = 0;
                                                return (
                                                    <MenuItems items={menu} key={index} depthLevel={depthLevel} setInitialPage={setInitialPage} />
                                                    // MenuItems(menu,index,depthLevel)
                                                );
                                            })}
                                        </ul>
                                    </div>
                                    {
                                        openCustomFrame && (loginstatus == true) ?
                                            (filerender == "iiadv" || filerender == "iicl" || sgstfile == true || circulartype != "" || notificationtype != ""|| orderfile!= ""|| gsttype!="") ?
                                                // <IIADVCL />
                                                setisforward(true)
                                                :
                                                (filerender == "content") ?
                                                    <ContentFrame
                                                        goBack={() => setOpencustomFrame(false)}
                                                        filepath={states.targetFile}
                                                        searchApi={searchapi}
                                                        rootUrl={url}
                                                        userid={userid}
                                                        MainFolder={mainFolder}
                                                        SubFolder={subfolder}
                                                        goHome={() => { setOpencustomFrame(false); setstate({ isOpenSearchModal: false }); }}
                                                    />
                                                    : null
                                            :
                                            null

                                        // {()=>checkstatus()}
                                    }


                                </div>

                            </div>
                            <div style={panelstyle}>
                                <div style={innerpanelstyle} >
                                    <div className='banner'>
                                            <div style={{ alignItems: 'center', textAlign: 'center', width: '100%', height: '100%' }}>
                                                {
                                                    banner == 'Banner' ?
                                                        null
                                                        :
                                                        banner != undefined ?
                                                        <>
                                                            {banner.map((bannerData, index) => {
                                                                if(bannerData.active == 1){
                                                                    if (bannerData.type.toLowerCase() == "img") {
                                                                        return (
                                                                            <div width={'100%'} height={'100%'}>
                                                                                <img src={bannerData.filepath} width={'380px'} height={'240px'} style={{cursor:'pointer',width:'99%',paddingRight: '5%'}} onClick={() => {
                                                                                    setmainFolder(bannerData.mainFolder)
                                                                                    setsubfolder(bannerData.subfolder)
                                                                                    setstate({ targetFile: bannerData.clickpath.substring(1, bannerData.clickpath.length) })
                                                                                    setfilerender('content')
                                                                                    setOpencustomFrame(true)
                                                                                    { console.log(bannerData, index) }
                                                                                    if(loginstatus == false){
                                                                                        window.top.location.href = `${sessionStorage.getItem('Apipathurl')}login`;
                                                                                    }
                                                                                }}/>
                                                                            </div>
                                                                        );
                                                                    }else if (bannerData.type.toLowerCase() == "link") {
                                                                        return (
                                                                            <div className='PanelLink'
                                                                                style={{ color: 'firebrick', cursor: 'pointer',display:'inline-block',width:'380px',textAlign: 'justify'}}
                                                                                onClick={() => {
                                                                                    setmainFolder(bannerData.mainFolder)
                                                                                    setsubfolder(bannerData.subfolder)
                                                                                    setstate({ targetFile: tileData.filepath.substring(1, bannerData.filepath.length) })
                                                                                    setfilerender('content')
                                                                                    setOpencustomFrame(true)
                                                                                    { console.log(bannerData, index) }
                                                                                    if(loginstatus == false){
                                                                                        window.top.location.href = `${sessionStorage.getItem('Apipathurl')}login`;
                                                                                    }
                                                                                }}>
                                                                                {bannerData.data}
                                                                            </div>
                                                                        );
                                                                    }else if (bannerData.type.toLowerCase() == "text") {
                                                                        return (
                                                                        <>
                                                                            <div dangerouslySetInnerHTML={{ __html: bannerData.data }}></div>
                                                                        </>
                                                                        );
                                                                    }
                                                                }

                                                            })}
                                                        </>:null
                                                }
                                            </div>
                                        </div>
                                    <div className='tilestyles'>
                                        <div className='box'>
                                            <div style={{ alignItems: 'center', textAlign: 'center', width: '100%', height: '100%',marginBottom: '7%' }}>
                                                {
                                                    tile1 == 'Tile' ?
                                                        null
                                                        :
                                                        tile1 != undefined ?
                                                        <>
                                                            {tile1.map((tileData, index) => {

                                                                if (tileData.type.toLowerCase() == "img") {

                                                                    return (
                                                                        <div width={'100%'} height={'100%'}>
                                                                            <img src={tileData.filepath} width={'380px'} height={'240px'} />
                                                                        </div>
                                                                    );
                                                                }else if (tileData.type.toLowerCase() == "link") {
                                                                    return (
                                                                        <div className='PanelLink'
                                                                            style={{ color: 'firebrick', cursor: 'pointer',display:'inline-block',width:'380px',textAlign: 'justify'}}
                                                                            onClick={() => {
                                                                                setmainFolder(tileData.mainFolder)
                                                                                setsubfolder(tileData.subfolder)
                                                                                setstate({ targetFile: tileData.filepath.substring(1, tileData.filepath.length) })
                                                                                setfilerender('content')
                                                                                setOpencustomFrame(true)
                                                                                { console.log(tileData, index) }
                                                                                if(loginstatus == false){
                                                                                    window.top.location.href = `${sessionStorage.getItem('Apipathurl')}login`;
                                                                                }
                                                                            }}>
                                                                            {tileData.data}
                                                                        </div>
                                                                    );
                                                                }else if (tileData.type.toLowerCase() == "text") {
                                                                    return (
                                                                    <>
                                                                        <div dangerouslySetInnerHTML={{ __html: tileData.data }}></div>
                                                                    </>
                                                                    );
                                                                }
                                                            })}
                                                        </>:null
                                                }
                                            </div>

                                        </div>

                                        <div className='box'>

                                            <div style={{ alignItems: 'center', textAlign: 'center', width: '100%', height: '100%',marginBottom: '7%'  }}>

                                            {
                                                    tile2 == 'Tile' ?
                                                        null
                                                        :
                                                        tile2 != undefined ?
                                                        <>
                                                            {tile2.map((tileData, index) => {

                                                                if (tileData.type.toLowerCase() == "img") {

                                                                    return (
                                                                        <div width={'100%'} height={'100%'}>
                                                                            <img src={tileData.filepath} width={'380px'} height={'240px'} />
                                                                        </div>
                                                                    );
                                                                }else if (tileData.type.toLowerCase() == "link") {
                                                                    return (
                                                                        <div className='PanelLink'
                                                                            style={{ color: 'firebrick', cursor: 'pointer',display:'inline-block',width:'380px',textAlign: 'justify' }}
                                                                            onClick={() => {
                                                                                setmainFolder(tileData.mainFolder)
                                                                                setsubfolder(tileData.subfolder)
                                                                                setstate({ targetFile: tileData.filepath.substring(1, tileData.filepath.length) })
                                                                                setfilerender('content')
                                                                                setOpencustomFrame(true)
                                                                                { console.log(tileData, index) }
                                                                                if(loginstatus == false){
                                                                                    window.top.location.href = `${sessionStorage.getItem('Apipathurl')}login`;
                                                                                }
                                                                            }}>
                                                                            {tileData.data}
                                                                        </div>
                                                                    );
                                                                }else if (tileData.type.toLowerCase() == "text") {
                                                                    return (
                                                                    <>
                                                                        <div dangerouslySetInnerHTML={{ __html: tileData.data }}></div>
                                                                    </>
                                                                    );
                                                                }
                                                            })}
                                                        </>:null
                                                }
                                            </div>
                                        </div>


                                        <div className='box'>

                                            <div style={{alignItems: 'center', textAlign: 'center', width: '100%', height: '100%',marginBottom: '7%' }}>               
                                            
                                            {
                                                    tile3 == 'Tile' ?
                                                        null
                                                        :
                                                        tile3 != undefined ?
                                                        <>
                                                            {tile3.map((tileData, index) => {

                                                                if (tileData.type.toLowerCase() == "img") {

                                                                    return (
                                                                        <div width={'100%'} height={'100%'}>
                                                                            <img src={tileData.filepath} width={'380px'} height={'240px'} />
                                                                        </div>
                                                                    );
                                                                }else if (tileData.type.toLowerCase() == "link") {
                                                                    return (
                                                                        <div className='PanelLink'
                                                                            style={{ color: 'firebrick', cursor: 'pointer',display:'inline-block',width:'380px',textAlign: 'justify' }}
                                                                            onClick={() => {
                                                                                setmainFolder(tileData.mainFolder)
                                                                                setsubfolder(tileData.subfolder)
                                                                                setstate({ targetFile: tileData.filepath.substring(1, tileData.filepath.length) })
                                                                                setfilerender('content')
                                                                                setOpencustomFrame(true)
                                                                                { console.log(tileData, index) }
                                                                                if(loginstatus == false){
                                                                                    window.top.location.href = `${sessionStorage.getItem('Apipathurl')}login`;
                                                                                }
                                                                            }}>
                                                                            {tileData.data}
                                                                        </div>
                                                                    );
                                                                }else if (tileData.type.toLowerCase() == "text") {
                                                                    return (
                                                                    <>
                                                                        <div dangerouslySetInnerHTML={{ __html: tileData.data }}></div>
                                                                    </>
                                                                    );
                                                                }
                                                            })}
                                                        </>:null
                                                }
                                            </div>
                                        </div>

                                        <div className='box'>
                                            <div style={{ alignItems: 'center', textAlign: 'center',marginBottom: '7%' }}>
                                          
                                            {
                                                    tile4 == 'Tile' ?
                                                        null
                                                        :
                                                        tile4 != undefined ?
                                                        <>
                                                            {tile4.map((tileData, index) => {

                                                                if (tileData.type.toLowerCase() == "img") {

                                                                    return (
                                                                        <div width={'100%'} height={'100%'}>
                                                                            <img src={tileData.filepath} width={'380px'} height={'240px'} />
                                                                        </div>
                                                                    );
                                                                }else if (tileData.type.toLowerCase() == "link") {
                                                                    return (
                                                                        <div className='PanelLink'
                                                                            style={{ color: 'firebrick', cursor: 'pointer',display:'inline-block',width:'380px',textAlign: 'justify' }}
                                                                            onClick={() => {
                                                                                setmainFolder(tileData.mainFolder)
                                                                                setsubfolder(tileData.subfolder)
                                                                                setstate({ targetFile: tileData.filepath.substring(1, tileData.filepath.length) })
                                                                                setfilerender('content')
                                                                                setOpencustomFrame(true)
                                                                                { console.log(tileData, index) }
                                                                                if(loginstatus == false){
                                                                                    window.top.location.href = `${sessionStorage.getItem('Apipathurl')}login`;
                                                                                }
                                                                            }}>
                                                                            {tileData.data}
                                                                        </div>
                                                                    );
                                                                }else if (tileData.type.toLowerCase() == "text") {
                                                                    return (
                                                                    <>
                                                                        <div dangerouslySetInnerHTML={{ __html: tileData.data }}></div>
                                                                    </>
                                                                    );
                                                                }
                                                            })}
                                                        </>:null
                                                }
                                            </div>
                                        </div>

                                        <div className='box'>
                                            <div style={{ alignItems: 'center', textAlign: 'center',marginBottom: '7%' }}>
                                          
                                            {
                                                    tile5 == 'Tile' ?
                                                        null
                                                        :
                                                        tile5 != undefined ?
                                                        <>
                                                            {tile5.map((tileData, index) => {

                                                                if (tileData.type.toLowerCase() == "img") {

                                                                    return (
                                                                        <div width={'100%'} height={'100%'}>
                                                                            <img src={tileData.filepath} width={'380px'} height={'240px'} />
                                                                        </div>
                                                                    );
                                                                }else if (tileData.type.toLowerCase() == "link") {
                                                                    return (
                                                                        <div className='PanelLink'
                                                                            style={{ color: 'firebrick', cursor: 'pointer',display:'inline-block',width:'380px',textAlign: 'justify' }}
                                                                            onClick={() => {
                                                                                setmainFolder(tileData.mainFolder)
                                                                                setsubfolder(tileData.subfolder)
                                                                                setstate({ targetFile: tileData.filepath.substring(1, tileData.filepath.length) })
                                                                                setfilerender('content')
                                                                                setOpencustomFrame(true)
                                                                                { console.log(tileData, index) }
                                                                                if(loginstatus == false){
                                                                                    window.top.location.href = `${sessionStorage.getItem('Apipathurl')}login`;
                                                                                }
                                                                            }}>
                                                                            {tileData.data}
                                                                        </div>
                                                                    );
                                                                }else if (tileData.type.toLowerCase() == "text") {
                                                                    return (
                                                                    <>
                                                                        <div dangerouslySetInnerHTML={{ __html: tileData.data }}></div>
                                                                    </>
                                                                    );
                                                                }
                                                            })}
                                                        </>:null
                                                }
                                            </div>
                                        </div>
                                        <div className='box'>
                                            <div style={{ alignItems: 'center', textAlign: 'center', marginBottom: '7%' }}>
                                            {
                                                    tile6 == 'Tile' ?
                                                        null
                                                        :
                                                        tile6 != undefined ?
                                                        <>
                                                            {tile6.map((tileData, index) => {

                                                                if (tileData.type.toLowerCase() == "img") {

                                                                    return (
                                                                        <div width={'100%'} height={'100%'}>
                                                                            <img src={tileData.filepath} width={'380px'} height={'240px'} />
                                                                        </div>
                                                                    );
                                                                }else if (tileData.type.toLowerCase() == "link") {
                                                                    return (
                                                                        <div className='PanelLink'
                                                                            style={{ color: 'firebrick', cursor: 'pointer',display:'inline-block',width:'380px',textAlign: 'justify' }}
                                                                            onClick={() => {
                                                                                setmainFolder(tileData.mainFolder)
                                                                                setsubfolder(tileData.subfolder)
                                                                                setstate({ targetFile: tileData.filepath.substring(1, tileData.filepath.length) })
                                                                                setfilerender('content')
                                                                                setOpencustomFrame(true)
                                                                                { console.log(tileData, index) }
                                                                                if(loginstatus == false){
                                                                                    window.top.location.href = `${sessionStorage.getItem('Apipathurl')}login`;
                                                                                }
                                                                            }}>
                                                                            {tileData.data}
                                                                        </div>
                                                                    );
                                                                }else if (tileData.type.toLowerCase() == "text") {
                                                                    return (
                                                                    <>
                                                                        <div dangerouslySetInnerHTML={{ __html: tileData.data }}></div>
                                                                    </>
                                                                    );
                                                                }
                                                            })}
                                                        </>:null
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        states.isOpenBookmarkModal &&
                                        <BookmarkKeeping
                                            // rootUrl={sessionStorage.getItem("url")}
                                            rootUrl={url}
                                            userid={userid}
                                            onCloseModal={() => setstate({ isOpenBookmarkModal: false })}
                                            openHistoryFile={(fileData) => { console.log(fileData); sethistoryFileData(fileData); setisReferedByHistory(true); setstate({ isOpenSearchModal: true, isOpenBookmarkModal: false }) }}
                                        // openHistoryFile={(fileData) => setState({ historyFileData: fileData, isReferedByHistory: true, isOpenSearchModal: true, isOpenBookmarkModal: false })}
                                        />
                                    }

                                    {
                                        states.isOpenHistoryModal &&
                                        <HistoryKeeping
                                            rootUrl={url}
                                            userid={userid}
                                            onCloseModal={() => setstate({ isOpenHistoryModal: false })}
                                            openHistoryFile={(fileData) => { sethistoryFileData(fileData); setisReferedByHistory(true); setstate({ isOpenSearchModal: true, isOpenBookmarkModal: false }) }}
                                        // openHistoryFile={(fileData) => setstate({ historyFileData: fileData, isReferedByHistory: true, isOpenSearchModal: true, isOpenHistoryModal: false })}
                                        />
                                    }


                                    {
                                        isOpenHSNSearch &&
                                        <Hsnsearch
                                            userid={userid}
                                            onCloseModal={() => setisOpenHSNSearch(false)}
                                        />
                                    }
                                    {
                                        isOpenDictModal &&
                                        <Dictionary
                                            rootUrl={url}
                                            userid={userid}
                                            onCloseModal={() => setisOpenDictModal(false)}
                                        />

                                    }

                                    {

                                        isOpenCompareLaws &&
                                        <Comparelaws onCloseModal={() => setisOpenCompareLaws(false)}/>
                                    }
                                    {
                                        isOpenSupport &&
                                        <Support
                                            openModal={isOpenSupport}
                                            closeModal={() => setisOpenSupport(false)}
                                            userDetails={userDetails}
                                        />
                                    }
                                </div>
                                {/* <Tooltip title="Help" >
                                <div className='supportBtn' onClick={() => setisOpenSupport(true)}>
                                    <img src={SupportIcon} alt="" />
                                </div>
                                </Tooltip > */}
                            </div>
                            {/* </div> */}
                        </>
            }
            <Modal centered size="sm" id="loadingModal" animation={true} show={isLoadingModalOpen} onHide={() => setisLoadingModalOpen(false)} style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} backdrop="static" keyboard={false}>
                <Modal.Body>
                    <div style={{ display: 'flex', alignItems: 'center', padding: "8px 8px 16px 16px", height: "10vh" }}>
                        <Spinner variant='dark' animation='border' />
                        <span style={{ paddingLeft: "20px" }}>Loading...</span>
                    </div>
                </Modal.Body>
            </Modal>
            {
                <Modal fullscreen={true} id="documentmodal" animation={true} show={states.isOpenSearchModal} onHide={() => setstate({ isOpenSearchModal: false })}>
                    <Modal.Body>
                        <Searched
                            searchApi={searchapi}
                            rootUrl={url}
                            goBack={() => { setisReferedByHistory(false); setstate({ isOpenSearchModal: false, isReferedByHistory: false, isReferedByHsn: false }) }}
                            userid={userid}
                            isReferedByHsn={states.isReferedByHsn}
                            hsnSearchWord={states.hsnSearchWord}
                            hsnPath={states.hsnPath}
                            isReferedByHistory={isReferedByHistory}
                            historyFileData={historyFileData}
                            goHome={() => setstate({ isOpenSearchModal: false })}
                            
                        />
                    </Modal.Body>
                </Modal>
            }
        </>
    )
}

export default Homescreen